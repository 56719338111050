.cmpp-container-desktop {
    background-color: #101010;
    width: 100%;
    flex: 1;
    position: relative;
}

#cmpp-stars-desktop {
    min-width: 100%;
    position: absolute;
    top: 2%;
    height: auto;
    z-index: 1;
    pointer-events: none;
    opacity: 1;
}

#cmpp-planet-desktop {
    position: relative;
    z-index: 1;
}

.cmpp-landing-text-container-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding-top: 15vh;
    margin: 0;
    background: radial-gradient(circle at 50% 100%, var(--foreground-one) 0%, var(--background-color) 25%);
    z-index: 0;
    position: relative;
}

#cmpp-landing-subtext-desktop{
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid transparent;
    background:
        linear-gradient(to right, #101010, #101010),
        linear-gradient(90deg, rgb(50, 148, 206) 0%, rgb(97, 196, 253) 10%, rgb(50, 148, 206) 60%,  rgb(105, 198, 252) 75%, rgb(50, 148, 206) 100%);;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    font-weight: 700;
    z-index: 10;
    padding-inline: 10px;
    padding-block: 5px;
    margin-bottom: 15px;
    width: fit-content;
    cursor: pointer;
}

#cmpp-landing-subtext-desktop p {
    text-shadow: none;
    font-size: clamp(0.15rem, 1.5vw, 1rem);
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.cmpp-landing-text-container-desktop h1 {
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: var(--font);
    font-weight: 700;
    font-size: clamp(5rem, 1.5vw, 8rem);
    color: var(--text);
}

.cmpp-landing-text-container-desktop p {
    padding: 0;
    padding-top: 16px;
    margin: 0;
    text-align: center;
    font-family: var(--font);
    font-weight: 400;
    font-size: clamp(2rem, 1.5vw, 6rem);
    color: var(--text);
}

#cmpp-landing-image-desktop {
    max-width: 60%;
}

@media(max-width: 1800px) {
    #cmpp-landing-image-desktop {
        max-width: 100%;
    }
}

#cmpp-buytrial-section-desktop {
    width: calc(100% - 128px);
    margin-top: -10%;
    margin-left: 64px;
    margin-right: 64px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

#cmpp-buytrial-container-desktop {
    width: 60%;
    padding-inline: 64px;
    padding-block: 32px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: var(--background-color);
}

@media(max-width: 1800px) {
    #cmpp-buytrial-container-desktop {
        width: 100%;
    }
}

#cmpp-buytrial-container-desktop div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

#cmpp-buytrial-container-desktop * {
    color: var(--text);
    margin: 0;
    padding: 0;
    font-family: 'Inter', serif;
    font-weight: 400;
}

#cmpp-buy-link-desktop{
    background-color: #F5F5F5;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#cmpp-buy-link-desktop h4 {
    padding-inline: 25px;
    padding-block: 10px;
    font-weight: 700;
    font-size: clamp(1.25rem, 1.5vw, 3.25rem);
}

#cmpp-trial-link-desktop{
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid transparent;
    background:
        linear-gradient(to right, #101010, #101010),
        linear-gradient(90deg, rgb(50, 148, 206) 0%, rgb(97, 196, 253) 10%, rgb(50, 148, 206) 60%,  rgb(105, 198, 252) 75%, rgb(50, 148, 206) 100%);;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    cursor: pointer;
}

#cmpp-trial-link-desktop h4{
    padding-inline: 25px;
    padding-block: 10px;
    font-weight: 700;
    font-size: clamp(1.25rem, 1.5vw, 3.25rem);
    text-shadow: none;
}

#cmpp-buytrial-container-desktop h3 {
    font-weight: 600;
    font-size: clamp(2rem, 1.5vw, 4rem);
    padding: 0;
    margin: 0;
}

#cmpp-buytrial-container-desktop p {
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
    padding-bottom: 32px;
}

#cmpp-easter-section-desktop {
    width: calc(100% - 128px);
    margin-left: 64px;
    margin-right: 64px;
    margin-top: 64px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

#cmpp-easter-container-desktop {
    width: 60%;
    padding-inline: 64px;
    padding-block: 32px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: var(--background-color);
}

@media(max-width: 1800px) {
    #cmpp-easter-container-desktop {
        width: 100%;
    }
}

#cmpp-easter-container-desktop * {
    color: var(--text);
    margin: 0;
    padding: 0;
    font-family: 'Inter', serif;
    font-weight: 200;
}

#cmpp-easter-container-desktop h3 {
    font-weight: 600;
    font-size: clamp(2rem, 1.5vw, 4rem);
    padding-bottom: 32px;
}

#cmpp-easter-container-desktop p {
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
    padding-bottom: 32px;
}

.gradient-text {
    background: linear-gradient(90deg, rgb(126, 177, 212) 0%, #3294ce 10%, rgb(126, 177, 212) 60%, #3294ce 75%, rgb(126, 177, 212) 100%);
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--font);
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
    animation: gradientShift 10s infinite linear;
}

@keyframes gradientShift {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 200% 0%;
    }
}

#cmpp-easter-videos-container-desktop {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-content: center;
}

#cmpp-easter-videos-container-desktop div {
    width: 50%;
    margin-inline: 32px;
    padding-bottom: 32px;
}

#cmpp-easter-videos-container-desktop div h4 {
    font-family: var(--font);
    font-size: clamp(1.5rem, 1.5vw, 1.75rem);
    text-align: center;
    padding-bottom: 8px;
}

#cmpp-clashes-section-desktop {
    width: calc(100% - 128px);
    margin-top: 128px;
    margin-left: 64px;
    margin-right: 64px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

#cmpp-clashes-container-desktop {
    width: 60%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.0) 100%);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 25px;
    backdrop-filter: blur(10px);
}

@media(max-width: 1800px) {
    #cmpp-clashes-container-desktop {
        width: 100%;
    }
}

#cmpp-clashes-live-container-desktop {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-right: 64px;
    box-sizing: border-box;
    color: var(--text);
}

#cmpp-clashes-live-container-desktop div {
    margin-left: 8px;
    animation: blinking 1s alternate;
    animation-iteration-count: infinite;
    transform: translateY(2px);
}


@keyframes blinking {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

#cmpp-clashes-child-container-desktop {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

#cmpp-clashes-child-container-desktop * {
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 700;
    text-align: center;
    padding: 16px 0px;
    margin: 0;
}

#cmpp-clashes-child-container-desktop h5{
    font-size: clamp(1.5rem, 1.5vw, 3rem);
}

#cmpp-clashes-child-container-desktop p{
    font-size: clamp(10rem, 1.5vw, 12rem);
}

#cmpp-why-section-desktop {
    width: calc(100% - 128px);
    margin-top: 128px;
    margin-left: 64px;
    margin-right: 64px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

#cmpp-why-container-desktop{
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media(max-width: 1800px) {
    #cmpp-why-container-desktop {
        width: 100%;
    }
}

#cmpp-why-container-desktop div {
    width: 55%;
    background-color: var(--background-color);
    margin-right: 50px;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    padding-inline: 64px;
    padding-block: 32px;
    box-sizing: border-box;
}

#cmpp-why-container-desktop div * {
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

#cmpp-why-container-desktop div h4 {
    font-weight: 700;
    font-size: clamp(1.75rem, 1.5vw, 3.25rem);
}

#cmpp-why-container-desktop div p {
    margin-top: 16px;
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
}

#cmpp-why-container-desktop img {
    width: 30%;
    object-fit: contain;
}

@media(max-width: 1800px) {
    #cmpp-why-container-desktop img {
        width: 40%;
    }
}

#cmpp-compatability-section-desktop {
    width: 100%;
}

#cmpp-compatability-section-desktop {
    width: calc(60% - 128px);
    margin-top: 128px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

@media(max-width: 1800px) {
    #cmpp-compatability-section-desktop{
        width: calc(100% - 128px);
    }
}

#cmpp-compatability-section-desktop * {
    color: var(--text);
    font-family: 'Inter', serif;
    padding: 0;
    margin: 0;
    text-align: center;
}

#cmpp-compatability-section-desktop h4 {
    font-weight: 700;
    font-size: clamp(2rem, 1.5vw, 3.5rem);
}

#cmpp-compatability-section-desktop p {
    font-weight: 400;
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
    padding-top: 16px;
}

#cmpp-compatability-software-desktop {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    margin-top: 64px;
    justify-content: space-evenly;
    align-content: center;
}

.cmpp-software-image-desktop {
    width: 13%;
    object-fit: contain;
}

#cmpp-look-section-desktop{
    width: calc(100% - 128px);
    background-color: var(--background-color);
    margin-block: 128px;
    margin-left: 64px;
    margin-right: 64px;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

#cmpp-look-section-desktop img{
    width: 105%;
    position: absolute;
    z-index: 0;
    margin-top: 164px;
}

#cmpp-look-container-desktop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding-inline: 64px;
    padding-block: 32px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: var(--background-color);
    position: relative;
}

@media(max-width: 1800px) {
    #cmpp-look-container-desktop{
        width: calc(100% - 128px);
    }
}

#cmpp-look-container-desktop * {
    color: var(--text);
    margin: 0;
    padding: 0;
    font-family: 'Inter', serif;
    font-weight: 200;
}

#cmpp-look-container-desktop h3 {
    font-weight: 600;
    font-size: clamp(2rem, 1.5vw, 4rem);
    padding-bottom: 32px;
}

#cmpp-look-container-desktop p {
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
    padding-top: 32px;
    text-align: center;
    width: 80%;
}

#cmpp-revit-model-desktop{
    position: relative;
    width: 1000px;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media(max-width: 1800px) {
    #cmpp-revit-model-desktop{
        width: 800px;
        height: 500px;
    }
}

@media(max-width: 1000px) {
    #cmpp-revit-model-desktop{
        width: 500px;
        height: 300px;
    }
}

.spinner {
    border: 4px solid var(--foreground-two); 
    border-top: 4px solid #3294ce; 
    border-radius: 50%;
    width: 40px; 
    height: 40px;
    animation: spin 1s linear infinite; 
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1.0;
    }
}

#cmpp-buy-section-desktop{
    width: calc(100% - 128px);
    padding-bottom: 128px;
    margin-left: 64px;
    margin-right: 64px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

#cmpp-buy-container-desktop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
    padding-inline: 64px;
    padding-block: 32px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: var(--background-color);
    position: relative;
}


@media(max-width: 1800px) {
    #cmpp-buy-container-desktop{
        width: calc(100% - 128px);
    }
}


#cmpp-buy-container-desktop * {
    font-family: 'Inter', serif;
    color: var(--text);
    font-weight: 400;
    padding: 0;
    margin: 0;
    text-align: left;
}

#cmpp-buy-container-desktop p{
    width: 100%;
    padding-bottom: 4px;
}

#cmpp-buy-container-desktop h4{
    font-weight: 700;
    font-size: clamp(1.5rem, 1.5vw, 3.5rem);
}

#cmpp-buy-container-desktop div{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 32px;
}

#cmpp-get-cm-button-desktop{
    background-color: var(--button-primary);
    text-decoration: none;
    border-radius: 50px;
    border: none;
    margin-left: 16px;
    cursor: pointer;
}

#cmpp-get-cm-button-desktop h4{
    padding-inline: 25px;
    padding-block: 10px;
    font-weight: 700;
    font-size: clamp(1.25rem, 1.5vw, 3.25rem);
}

#cmpp-try-cm-button-desktop{
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid transparent;
    background:
        linear-gradient(to right, #101010, #101010),
        linear-gradient(90deg, rgb(50, 148, 206) 0%, rgb(97, 196, 253) 10%, rgb(50, 148, 206) 60%,  rgb(105, 198, 252) 75%, rgb(50, 148, 206) 100%);;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    margin-left: 16px;
    cursor: pointer;
}

#cmpp-try-cm-button-desktop h4{
    padding-inline: 25px;
    padding-block: 10px;
    font-weight: 700;
    font-size: clamp(1.25rem, 1.5vw, 3.25rem);
    text-shadow: none;
}

/*//////////MOBILE STYLES//////////////*/

.cmpp-container-mobile {
    background-color: #101010;
    width: 100%;
    flex: 1;
    position: relative;
}

#cmpp-stars-mobile {
    width: 100%;
    position: absolute;
    top: 1%;
    height: auto;
    z-index: 1;
    pointer-events: none;
    opacity: 1;
}

#cmpp-planet-mobile {
    position: relative;
    z-index: 0;
}

.cmpp-landing-text-container-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding-top: 15vh;
    margin: 0;
    background: radial-gradient(circle at 50% 100%, var(--foreground-one) 0%, var(--background-color) 25%);
    z-index: 0;
}

#cmpp-landing-subtext-mobile{
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid transparent;
    background:
        linear-gradient(to right, #101010, #101010),
        linear-gradient(90deg, rgb(50, 148, 206) 0%, rgb(97, 196, 253) 10%, rgb(50, 148, 206) 60%,  rgb(105, 198, 252) 75%, rgb(50, 148, 206) 100%);;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    font-weight: 700;
    z-index: 10;
    padding-inline: 10px;
    padding-block: 5px;
    margin-bottom: 16px;
}

#cmpp-landing-subtext-mobile p {
    text-shadow: none;
    font-size: clamp(0.6rem, 1.5vw, 1.25rem);
    padding: 0;
    margin: 0;
}

.cmpp-landing-text-container-mobile h1 {
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: var(--font);
    font-weight: 700;
    font-size: clamp(2.5rem, 1.5vw, 8rem);
    color: var(--text);
    z-index: 1;
}

.cmpp-landing-text-container-mobile p {
    padding: 0;
    padding-top: 16px;
    margin-block: 0;
    margin-inline: 8px;
    text-align: center;
    font-family: var(--font);
    font-weight: 400;
    font-size: clamp(1.25rem, 1.5vw, 6rem);
    color: var(--text);
    z-index: 5;
}

#cmpp-landing-image-mobile {
    max-width: 100%;
    z-index: 5;
}

@media(max-width: 1800px) {
    #cmpp-landing-image-mobile {
        max-width: 125%;
    }
}

#cmpp-buytrial-section-mobile {
    width: 100%;
    margin-top: -10%;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

#cmpp-buytrial-container-mobile {
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
    padding-inline: 32px;
    padding-block: 32px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: var(--background-color);
}

#cmpp-buytrial-container-mobile div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

#cmpp-buytrial-container-mobile * {
    color: var(--text);
    margin: 0;
    padding: 0;
    font-family: 'Inter', serif;
    font-weight: 700;
}

#cmpp-buytrial-container-mobile div h3{
    font-size: clamp(1rem, 1.5vw, 3rem);
    padding-bottom: 16px;
}

#cmpp-buy-link-mobile{
    background-color: #F5F5F5;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

#cmpp-buy-link-mobile h4 {
    padding-inline: 25px;
    padding-block: 10px;
    font-weight: 700;
    font-size: clamp(1.25rem, 1.5vw, 3.25rem);
}

#cmpp-trial-link-mobile{
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid transparent;
    background:
        linear-gradient(to right, #101010, #101010),
        linear-gradient(90deg, rgb(50, 148, 206) 0%, rgb(97, 196, 253) 10%, rgb(50, 148, 206) 60%,  rgb(105, 198, 252) 75%, rgb(50, 148, 206) 100%);;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    width: 100%;
}

#cmpp-trial-link-mobile h4{
    padding-inline: 25px;
    padding-block: 10px;
    font-weight: 700;
    font-size: clamp(1.25rem, 1.5vw, 3.25rem);
    text-shadow: none;
    text-align: center;
}

#cmpp-buytrial-container-mobile h3 {
    font-weight: 600;
    font-size: clamp(2rem, 1.5vw, 4rem);
    padding: 0;
    margin: 0;
}

#cmpp-buytrial-container-mobile p {
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
    padding-bottom: 32px;
}

#cmpp-easter-section-mobile {
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 64px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

#cmpp-easter-container-mobile {
    width: 90%;
    padding-inline: 32px;
    padding-block: 32px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: var(--background-color);
}

@media(max-width: 1800px) {
    #cmpp-easter-container-mobile {
        width: 100%;
    }
}

#cmpp-easter-container-mobile * {
    color: var(--text);
    margin: 0;
    padding: 0;
    font-family: 'Inter', serif;
    font-weight: 200;
}

#cmpp-easter-container-mobile h3 {
    font-weight: 600;
    font-size: clamp(1.5rem, 1.5vw, 3.5rem);
    padding-bottom: 32px;
    text-align: center;
}

#cmpp-easter-container-mobile p {
    font-size: clamp(1rem, 1.5vw, 1.4rem);
    padding-bottom: 32px;
    text-align: center;
}

.gradient-text {
    background: linear-gradient(90deg, rgb(126, 177, 212) 0%, #3294ce 10%, rgb(126, 177, 212) 60%, #3294ce 75%, rgb(126, 177, 212) 100%);
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--font);
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.5);
    animation: gradientShift 10s infinite linear;
}

#cmpp-easter-videos-container-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-content: center;
}

#cmpp-easter-videos-container-mobile div {
    width: 100%;
    padding-bottom: 32px;
}

#cmpp-easter-videos-container-mobile div h4 {
    font-family: var(--font);
    font-size: clamp(1.25rem, 1.5vw, 1.5rem);
    text-align: center;
    padding-bottom: 8px;
}

#cmpp-clashes-section-mobile {
    width: calc(100% - 32px);
    margin-top: 64px;
    margin-left: 16px;
    margin-right: 16px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

#cmpp-clashes-container-mobile {
    width: 60%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.0) 100%);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    backdrop-filter: blur(10px);
}

@media(max-width: 1800px) {
    #cmpp-clashes-container-mobile {
        width: 100%;
    }
}

#cmpp-clashes-live-container-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
    box-sizing: border-box;
    color: var(--text);
}

#cmpp-clashes-live-container-mobile div {
    margin-left: 8px;
    animation: blinking 1s alternate;
    animation-iteration-count: infinite;
    transform: translateY(2px);
}

#cmpp-clashes-child-container-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

#cmpp-clashes-child-container-mobile * {
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 700;
    text-align: center;
    padding: 16px 0px;
    margin: 0;
}

#cmpp-clashes-child-container-mobile h5{
    font-size: clamp(1rem, 1.5vw, 2.5rem);
}

#cmpp-clashes-child-container-mobile p{
    font-size: clamp(5rem, 1.5vw, 8rem);
}

#cmpp-why-section-mobile {
    width: 100%;
    margin-top: 64px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

#cmpp-why-container-mobile{
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media(max-width: 1800px) {
    #cmpp-why-container-mobile {
        width: 100%;
    }
}

#cmpp-why-container-mobile div {
    width: 90%;
    background-color: var(--background-color);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    padding-inline: 32px;
    padding-block: 32px;
    box-sizing: border-box;
}

#cmpp-why-container-mobile div * {
    color: var(--text);
    font-family: 'Inter', serif;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

#cmpp-why-container-mobile div h4 {
    font-weight: 700;
    font-size: clamp(1.25rem, 1.5vw, 1.5rem);
}

#cmpp-why-container-mobile div p {
    margin-top: 16px;
    font-size: clamp(1rem, 1.5vw, 1.4rem);
}

#cmpp-why-container-mobile img {
    width: 90%;
    margin-top: 32px;
    object-fit: cover;
}

#cmpp-compatability-section-mobile {
    width: 100%;
}

#cmpp-compatability-section-mobile {
    width: calc(100% - 32px);
    margin-top: 64px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

#cmpp-compatability-section-mobile * {
    color: var(--text);
    font-family: 'Inter', serif;
    padding: 0;
    margin: 0;
    text-align: center;
}

#cmpp-compatability-section-mobile h4 {
    font-weight: 700;
    font-size: clamp(1.5rem, 1.5vw, 1.5rem);
}

#cmpp-compatability-section-mobile p {
    font-weight: 400;
    font-size: clamp(1rem, 1.5vw, 1.4rem);
    padding-top: 16px;
}

#cmpp-compatability-software-mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    margin-top: 64px;
    justify-content: space-evenly;
    align-content: center;
}

.cmpp-software-image-mobile {
    width: 14%;
    object-fit: contain;
}

#cmpp-look-section-mobile{
    width: calc(100% - 32px);
    background-color: var(--background-color);
    margin-block: 64px;
    margin-left: 16px;
    margin-right: 16px;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

#cmpp-look-section-mobile img{
    width: 125%;
    position: absolute;
    z-index: 0;
    margin-top: 164px;
}

#cmpp-look-container-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-inline: 32px;
    padding-block: 32px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: var(--background-color);
    position: relative;
}

#cmpp-look-container-mobile * {
    color: var(--text);
    margin: 0;
    padding: 0;
    font-family: 'Inter', serif;
    font-weight: 200;
}

#cmpp-look-container-mobile h3 {
    font-weight: 600;
    font-size: clamp(1.75rem, 1.5vw, 3rem);
    padding-bottom: 32px;
}

#cmpp-look-container-mobile p {
    font-size: clamp(1rem, 1.5vw, 1.4rem);
    padding-top: 32px;
    text-align: center;
    width: 100%;
}

#cmpp-revit-model-mobile{
    position: relative;
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinner {
    border: 4px solid var(--foreground-two); 
    border-top: 4px solid #3294ce; 
    border-radius: 50%;
    width: 40px; 
    height: 40px;
    animation: spin 1s linear infinite; 
}

.model-hidden{
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0;
}

.model-fade-in{
    display: block;
    opacity: 0;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
    animation: fade-in 0.5s linear forwards;
}

#cmpp-buy-section-mobile{
    width: calc(100% - 32px);
    padding-bottom: 64px;
    margin-left: 16px;
    margin-right: 16px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

#cmpp-buy-container-mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-inline: 32px;
    padding-block: 32px;
    box-sizing: border-box;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: var(--background-color);
    position: relative;
}

#cmpp-buy-container-mobile * {
    font-family: 'Inter', serif;
    color: var(--text);
    font-weight: 400;
    padding: 0;
    margin: 0;
    text-align: left;
}

#cmpp-buy-container-mobile p{
    width: 100%;
    padding-bottom: 4px;
    font-size: clamp(0.8rem, 1.5vw, 1rem);
}

#cmpp-buy-container-mobile h4{
    font-weight: 700;
    font-size: clamp(1.25rem, 1.5vw, 2.75rem);
    padding-bottom: 32px;
    text-align: center;
}

#cmpp-buy-container-mobile div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 32px;
}

#cmpp-get-cm-button-mobile{
    background-color: var(--button-primary);
    text-decoration: none;
    border-radius: 50px;
}

#cmpp-get-cm-button-mobile h4{
    padding-inline: 25px;
    padding-block: 10px;
    font-weight: 700;
    font-size: clamp(1.5rem, 1.5vw, 3.5rem);
}

#cmpp-try-cm-button-mobile{
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid transparent;
    background:
        linear-gradient(to right, #101010, #101010),
        linear-gradient(90deg, rgb(50, 148, 206) 0%, rgb(97, 196, 253) 10%, rgb(50, 148, 206) 60%,  rgb(105, 198, 252) 75%, rgb(50, 148, 206) 100%);;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
}

#cmpp-try-cm-button-mobile h4{
    padding-inline: 25px;
    padding-block: 10px;
    font-weight: 700;
    font-size: clamp(1.5rem, 1.5vw, 3.5rem);
    text-shadow: none;
}